import React, { useContext } from "react";
import { Router } from "@reach/router";
import { UserContext } from "./context/user";
import { ToastContainer } from "react-toastify";
import PrivateRoute from "./components/private-route";
// Pages
import Login from "./pages/login";
import Account from "./pages/account";
import Logout from "./pages/logout";
import Leads from "./pages/leads";
import Consent from "./pages/consent";
import ControlPanel from "./pages/control-panel";
import NotFound from "./pages/404";

function App() {
  const { user } = useContext(UserContext);

  process.env.NODE_ENV === "development" && console.log(user);

  return (
    <>
      <ToastContainer autoClose={3000} />
      <Router>
        <Login path="/" />
        <Account path="/account" />
        <Account path="/account/:token" />
        {user && (
          // User required routes
          <>
            <Leads path="/agent/:id" user={user} />
            <PrivateRoute
              as={ControlPanel}
              path="/control-panel/:type/"
              role={user.role}
            />
            <PrivateRoute
              as={ControlPanel}
              path="/control-panel/:type/:id"
              role={user.role}
            />
            {/* <PrivateRoute as={Users} path="/users/:id" role={user.role} /> */}
          </>
        )}
        <Consent path="/consent/" user={user} />
        <Consent path="/consent/:customer_id" user={user} />
        <Consent path="/consent/:customer_id/:agent_id" user={user} />
        <Logout path="/logout" />
        <NotFound default />
      </Router>
    </>
  );
}

export default App;
